// extracted by mini-css-extract-plugin
export var ArtistDescription = "MartinWong-module--ArtistDescription--rtTjv";
export var ArtistInfos = "MartinWong-module--ArtistInfos--S5cxO";
export var ButtonWrapper = "MartinWong-module--ButtonWrapper --lwovt";
export var CardWrapper = "MartinWong-module--CardWrapper--xYI-+";
export var CarrouselWrapper2 = "MartinWong-module--CarrouselWrapper2--ZN-8k";
export var Citations = "MartinWong-module--Citations--GBeS5";
export var DescriptionWrapper = "MartinWong-module--DescriptionWrapper--ejQiW";
export var ImageWrapper = "MartinWong-module--ImageWrapper--oQl01";
export var LinkWrapper = "MartinWong-module--LinkWrapper--gLbK1";
export var MobileProtrait = "MartinWong-module--MobileProtrait---GGAl";
export var More = "MartinWong-module--More--vRfev";
export var MoreButton = "MartinWong-module--MoreButton--M+KNY";
export var NameWrapper = "MartinWong-module--NameWrapper--dxLNC";
export var PdpWrapper = "MartinWong-module--PdpWrapper--IiKJR";
export var PhotosWrapper = "MartinWong-module--PhotosWrapper--VKFOj";
export var ProfilWrapper = "MartinWong-module--ProfilWrapper--zn3wk";
export var TitleWrapper = "MartinWong-module--TitleWrapper--Wf4l3";
export var Wrapper = "MartinWong-module--Wrapper--2ktBn";