import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./MartinWong.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import MartinWongPhoto1 from "../../../../res/Photos site/Martin Wong/martinbypeterbellamy..jpg"
import MartinWongPhoto2 from "../../../../res/Photos site/Martin Wong/martin-wong.jpeg"
import MartinWongPhoto3 from "../../../../res/Photos site/Martin Wong/4.jpg"
import MartinWongPhoto4 from "../../../../res/Photos site/Martin Wong/2.webp"
import MartinWongPhoto5 from "../../../../res/Photos site/Martin Wong/1.jpg"
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/wong/portrait.jpg'

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "MartinWong",
  name: "MartinWong",
  description:" Martin Wong est né à Portland, Oregon, en 1946 et il s’installe à New York en 1978. Son travail, fondé sur un réalisme visionnaire et doté d’une grande rigueur, constitue un des legs les plus remarquables de la scène artistique de l’East Village des années 80 et annonce l’intérêt des années 90 pour les questions identitaires. Il s’installe dans le Lower East Side de New York et fréquente l’hétérogène communauté hispanique qui y vit et qu’il représente dans de nombreux portraits. Des immeubles en briques délabrés aux graffiti disséminés sur tous les murs et aux boutiques aux grilles rouillées, en passant par ses voisins aux corps recouverts de tatouages de forçats : Wong peint la vie qui se déroule sous ses fenêtres, en recourant à un style unique alliant le décoratif au réel, le documentaire au fantastique, le religieux à l’érotique. Il meurt du sida en 1999, à l’âge de cinquante-trois ans. Le travail de Wong a été présenté dans de nombreuses expositions collectives dans les principaux musées des États-Unis, du Mexique, d’Espagne et d’Allemagne, ainsi que dans des expositions personnelles, comme celles au Bronx Museum avec la retrospective de l’artiste en 2015, au New Museum of Contemporary Art, au San Francisco Art Institute, à l’Exit Art et à la PPOW Gallery de New York. Son travail fait partie de la collection permanente du MoMa où il faisait partie de l’exposition The 1980’s : A new Génération, American Painters and Sculptors dès 1988, du Whitney Museum ainsi que de l’Art Institute of Chicago.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de MartinWong.",
  photos: [
    { src: MartinWongPhoto1, name: "MartinWong" },
    { src: MartinWongPhoto3, name: "MartinWong" },
    { src: MartinWongPhoto4, name: "MartinWong" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
  citations: []
};

const DondiWhite = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>MARTIN WONG <h2 style={{paddingLeft: "16px"}}>(Américain)</h2></h1>
          <h3 className={NameWrapper} >1946-1999</h3>
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        </div>
      </div>
      

      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default DondiWhite;